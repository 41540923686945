import React, { useMemo } from "react";
import { CellProps } from "./Cell";
import { BASE_COLUMN_TYPES, ColumnDataMap, REPORT_TOOLTIP_TARGET_ID } from "../config/types";
import { formatMoneyCurrency } from "../../../../utils";
import { useTooltipAttributes } from "../../../../hooks";
import { Csku, LayersIcon, Typography } from "@commonsku/styles";

type TColumn = typeof BASE_COLUMN_TYPES.Commission;
const CommissionCell = ({
  column,
  row,
  className,
  style = {},
  textStyle = {},
}: CellProps<TColumn>) => {
  const {
    accessorKey,
    dataTransform,
    moneyOptions: { decimalPlaces },
  } = column;

  const { amount, currency_id, commission_override, estimated, tooltipText } = useMemo<
    ColumnDataMap<TColumn>
  >(() => {
    if (!dataTransform) {
      throw new Error("CommissionCell requires dataTransform");
    }

    const { amount, currency_id, commission_override, estimated, tooltipText } =
      dataTransform(row.original[accessorKey]);

    return {
      amount,
      currency_id,
      commission_override,
      estimated,
      tooltipText,
    };
  }, [accessorKey, dataTransform, row.original]);

  const value = `${Boolean(estimated) ? "Est " : ""}${formatMoneyCurrency(
    amount,
    currency_id,
    decimalPlaces,
  )}`;
  const color = commission_override
    ? "var(--color-secondary3-70)"
    : "var(--color-neutrals-90)";

  const tooltipAttributes = useTooltipAttributes(tooltipText ?? value, REPORT_TOOLTIP_TARGET_ID);

  return (
    <Csku className={className} style={style} {...tooltipAttributes}>
      {commission_override && (
        <LayersIcon
          altText={"overridden"}
          color="var(--color-secondary3-70)"
          style={{ marginRight: "2px", verticalAlign: "middle" }}
        />
      )}
      <Typography.Span
        style={{
          display: "block",
          alignContent: "center",
          color,
          ...textStyle,
        }}
      >
        {value}
      </Typography.Span>
    </Csku>
  );
};

export default CommissionCell;
